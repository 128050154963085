/**
 * Original file from app_deborla cartridge
 * Modifications:
 * - Fix eslint issues.
 */

function updateQueryParams(url, params) {
    let urlObj = new URL(url);
    let searchParams = urlObj.searchParams;

    for (let param in params) {
        if (params.hasOwnProperty(param)) {
            searchParams.set(param, params[param]);
        }
    }

    return urlObj.toString();
}

if ($('.search-results').length > 0) {
    var priceInitial = $('[data-maxprice-ctg]')[0].dataset.maxpriceCtg;
    var splitedPrice = priceInitial.substring(4, priceInitial.length);
    var maxPrice = Math.ceil(parseInt(splitedPrice)) + 1;

    $('.js-range-slider').ionRangeSlider({
        type: 'double',
        skin: 'round',
        min: 0,
        max: maxPrice,
        from: 0,
        onStart: function () {
            $('#filterFix').attr('filterfix', $('.sort-order-wrapper').find(':selected')
                .data('value'));
        },
        onChange: function () {
            $('#filterFix').attr('filterfix', $('.sort-order-wrapper').find(':selected')
                .data('value'));
        },
        onFinish: function (data) {
            var pricefrom = data.from;
            var priceTo = data.to;
            var newSortingUrl;
            var sorting = $('.sort-order-wrapper').find(':selected').data('value');
            if (sorting == undefined || sorting == null) {
                newSortingUrl = $('#filterFix').attr('filterfix');
                newSortingUrl = newSortingUrl.split('?');
                var urlFromResetButton = $('.reset-filters-button').attr('data-href').split('?');
                urlFromResetButton[1] = newSortingUrl[1];
                urlFromResetButton = urlFromResetButton.join();
                urlFromResetButton = urlFromResetButton.replace(',', '?');
                $('.reset-filters-button').attr('data-href', urlFromResetButton);
                // click the Button
                document.querySelector('[class*="reset-filters-desktop"]').click();
                $('.reset-filters-button').attr('data-href', $('#filterFix').attr('reseturl'));
            } else {
                var res = sorting.replace('Search-UpdateGrid', 'Search-ShowAjax');

                let updatedUrl = updateQueryParams(res, {
                    pmin: pricefrom,
                    pmax: priceTo
                });

                $('.js-range-slider').data('href', updatedUrl);

                $('body').trigger('filter:priceChange');
                sorting = sorting.split('sz=');
                sorting[1] = 12;

                newSortingUrl = sorting.join().replace(',', 'sz=');
                setTimeout(function () {
                    $('.sort-order-wrapper').find(':selected').attr('value',
                        newSortingUrl);
                    $('.sort-order-wrapper').find(':selected').attr('data-value',
                        newSortingUrl);
                }, 500);
                $('.sort-order-wrapper').find(':selected').attr('value', newSortingUrl);
                $('.sort-order-wrapper').find(':selected').attr('data-value', newSortingUrl);
            }
        }
    });
}


$('[data-filters-reset]').on('click', function () {
    var sliderjs = $('.js-range-slider').data('ionRangeSlider');
    sliderjs.reset();
});

$('[data-filters-reset-desktop]').on('click', function () {
    var selectorDesktop = $('.refinement-desktop .js-range-slider').data('ionRangeSlider');
    selectorDesktop.reset();
});

$(document).ready(function () {
    var defaultReset = $('.reset-filters-button').attr('data-href');
    $('#filterFix').attr('reseturl', defaultReset);
});

$(document).ready(function () {
    $('.sort-order-wrapper .custom-select').each(function () {
        $(this).val($(this).find('option[selected]').val());
    });
});
